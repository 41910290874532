/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap") */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-multiple-carousel__arrow--right {
  right: 0px !important;
}

.react-multiple-carousel__arrow--left {
  left: 0px !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: #2b2a2a; /* color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the thumb */
  border-radius: 16px; /* roundness of the thumb */
}
html,
body {
  overflow-x: hidden;
  position: relative;
}
